import { useState, useMemo, memo, useEffect, useCallback } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import CalculateIcon from '@mui/icons-material/Calculate';
import Grid from 'Components/Shared/Grid/grid.component';
import Calculator from '../Calculator/calculator.component';
import { formatPrice, formatVolume } from '@lib/helpers/helpers';
import {
  ARABICA_CONT_CAPACITY,
  containerCalculator,
} from '@lib/helpers/ContainerCalculator/container-calculator.helper';
import { weightConverter } from '@lib/helpers/WeightConverter/weight-converter.helper';
import { useQuoteStepContext } from '@lib/Context/QuoteStep/quote-step.context';
import MuiOutlinedInput from '@atoms/Fields/ReactFormFields/FormInput/MuiOutlinedInput/mui-outlined-input.component';
import ProductSummary from '@organisms/Products/ProductSummary/product-summary.component';
import SingleAccordion from '@organisms/Accordion/SingleAccordion/single-accordion.component';
import OutrightTotal from '@organisms/Products/ProductAccordion/OutrightTotal/outright-total.component';

import styles from './product-accordion.module.scss';
import { useManageDealContext } from '@lib/Context/ManageDealContext/manage-deal.context';
import { useMounted } from '@lib/hooks/useMounted';
import { convertCurrencyHelper } from '@lib/helpers/convert-currency.helper';
import { MuiAutocomplete } from '@atoms/Fields/ReactFormFields/MuiAutocomplete/mui-autocomplete.component';
import PriceBreakDown from '@organisms/HighChart/ChartPriceBreakdown/chart-price-breakdown.component';
import { calculateFutureContract } from '@lib/helpers/calculate-future-contracts';
import { getValue } from '@testing-library/user-event/dist/utils';

const ProductAccordion = ({
  index,
  product,
  onDelete,
  contracts,
  properties,
  validation,
  error,
}) => {
  const { t } = useTranslation();
  const {
    getValues,
    watch,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useFormContext();
  const { setMinContainers, showPriceBreakDown } = useQuoteStepContext();
  const { selectedStep } = useManageDealContext();
  const { NetWeightUnitId, Contract, Species } = getValues();
  const { ProductPlatformId, NetWeight, UnitPrice } = product;
  const watchProducts = watch(`Products`);
  const watchProductId = watch(`Products[${index}].ProductPlatformId`);
  const watchProductNetWeight = watch(`Products[${index}].NetWeight`);
  const watchPriceUnitPrice = watch(`Products[${index}].UnitPrice`);
  const watchPriceType = watch('PriceType');
  const watchNetWeightUnitId = watch('NetWeightUnitId');
  const watchSpecies = watch('Species');
  const watchPriceUnit = watch(`UnitPriceCurrencyId`);

  const [toggleCalculator, setToggleCalculator] = useState(false);
  const [minContainersValue, setMinContainersValue] = useState(0);
  const isMounted = useMounted();

  const accordionTitle = useCallback(() => {
    const productName =
      product.ProductPlatformId.Options?.[watchProductId]?.Name;
    const volume = watchProductNetWeight
      ? `${formatVolume(Number(watchProductNetWeight), NetWeightUnitId)}`
      : '--';

    if (watchPriceType === 'Outright') {
      if (productName) {
        return (
          <>
            {productName} | {volume} | <OutrightTotal index={index} />
          </>
        );
      } else {
        return <>{t('New Product')}</>;
      }
    } else if (watchPriceType === 'Differential') {
      if (productName) {
        const currency = convertCurrencyHelper(watchPriceUnit);
        const sign = watchPriceUnitPrice < 0 ? '-' : '+';
        const price = formatPrice(
          Math.abs(Number(watchPriceUnitPrice)),
          currency,
          sign
        );
        const netWeight = watchSpecies === 'Coffee Robusta' ? 'MT' : 'lbs';
        const priceWithUnit = `${price}/${netWeight}`;

        return (
          <>
            {productName} | {volume} | {Contract || '--'}{' '}
            {(watchPriceUnitPrice && priceWithUnit) || ' --'}
          </>
        );
      } else {
        return <>{t('New Product')}</>;
      }
    } else {
      return <>{t('New Product')}</>;
    }
  }, [
    product.ProductPlatformId.Options,
    watchProductId,
    watchProductNetWeight,
    NetWeightUnitId,
    Contract,
    watchPriceType,
    watchPriceUnitPrice,
    watchSpecies,
  ]);

  const productOptions = useMemo(() => {
    if (!Species) {
      return [];
    }
    const asArray = ProductPlatformId?.Options
      ? Object.entries(ProductPlatformId.Options)
      : [];
    const filtered = asArray.filter(([_, value]) => value.Species === Species);

    return Object.fromEntries(filtered);
  }, [ProductPlatformId.Options, Species]);

  const volumeLabel = useMemo(() => {
    const weightUnit = watchNetWeightUnitId ? `(${watchNetWeightUnitId})` : '';
    return `${NetWeight.Label} ${weightUnit}`;
  }, [NetWeight.Label, watchNetWeightUnitId]);

  const unitPriceLabel = useMemo(() => {
    const UnitPriceCurrencyId = getValues('UnitPriceCurrencyId');
    const currency = UnitPriceCurrencyId ? `${UnitPriceCurrencyId}` : '';
    const netWeight = watchSpecies === 'Coffee Robusta' ? 'MT' : 'lbs';
    return !watchPriceType || watchPriceType === 'Outright'
      ? `${t('OfferPrice')} (${currency}${
          watchNetWeightUnitId ? '/' + watchNetWeightUnitId : ''
        })`
      : `${t('Differential')} (${currency}${
          watchSpecies ? '/' + netWeight : ''
        })`;
  }, [getValues, watchSpecies, watchPriceType, t, watchNetWeightUnitId]);

  const onBlurProductNetWeightHandler = useCallback(() => {
    const containersCount = Math.ceil(
      Number(watchProductNetWeight) / ARABICA_CONT_CAPACITY
    );
    setMinContainersValue(containersCount);
    setValue(`Products[${index}].Containers`, containersCount);
  }, [index, setValue, watchProductNetWeight]);

  useEffect(() => {
    if (!isMounted) {
      return;
    }

    const totalNetWight = watchProducts.reduce(
      (acc, curr) => (acc += Number(curr.NetWeight || 0)),
      0
    );
    const weight = weightConverter({
      weight: totalNetWight,
      weightUnit: NetWeightUnitId,
    });
    const totalContainers = containerCalculator({
      weight,
      species: Species,
    });
    // const totalContainers = Math.ceil(totalNetWight / ARABICA_CONT_CAPACITY);
    setMinContainers(totalContainers);
    if (totalContainers < 0) {
      setError(`NumContainers`, {
        type: 'error',
        message: t('PositiveNumberValidation'),
      });
    } else {
      clearErrors(`NumContainers`);
    }

    setValue('NumContainers', totalContainers);
    setValue(
      'FutureContracts',
      calculateFutureContract({
        containers: totalContainers,
        species: watchSpecies,
      })
    );
  }, [
    NetWeightUnitId,
    Species,
    setMinContainers,
    setValue,
    watchProductNetWeight,
    watchProducts,
    watchSpecies,
  ]);

  // Calculate the Future Contracts based on the total products volume
  // useEffect(() => {
  //   if (!isMounted) {
  //     return;
  //   }

  //   let contracts = 0;

  //   const totalNetWight = watchProducts.reduce(
  //     (acc, curr) => (acc += Number(curr.NetWeight || 0)),
  //     0
  //   );
  //   const weight = weightConverter({
  //     weight: totalNetWight,
  //     weightUnit: NetWeightUnitId,
  //   });

  //   if (watchSpecies === 'Arabica') {
  //     contracts = weight / 17009.8;
  //   } else if (watchSpecies === 'Robusta') {
  //     contracts = weight / 10000;
  //   }

  //   setValue('FutureContracts', Math.ceil(contracts));
  // }, [watchProducts, watchProductNetWeight, watchNetWeightUnitId]);

  const unitPriceValidation = useCallback(
    (value) => {
      if (
        watchPriceType === 'Outright' &&
        Number(value) < 0 &&
        !selectedStep?.Done
      ) {
        return t('PositiveNumberValidation');
      }
      return true;
    },
    [t, watchPriceType]
  );

  const netWeightValidation = useCallback(
    (value) => {
      if (Number(value) < 0) {
        return t('PositiveNumberValidation');
      }
      return true;
    },
    [t]
  );

  const onChangeUnitPriceHandler = useCallback(
    (field, event) => {
      if (watchPriceType === 'Outright' && Number(event?.target?.value) < 0) {
        setError(`Products[${index}].UnitPrice`, {
          type: 'error',
          message: t('PositiveNumberValidation'),
        });
      } else {
        clearErrors(`Products[${index}].UnitPrice`);
      }
      field.onChange(event);
    },
    [clearErrors, index, setError, t, watchPriceType]
  );

  const onChangeNetWeightHandler = useCallback(
    (field, event) => {
      if (Number(event?.target?.value) < 0) {
        setError(`Products[${index}].NetWeight`, {
          type: 'error',
          message: t('PositiveNumberValidation'),
        });
      } else {
        clearErrors(`Products[${index}].NetWeight`);
      }
      field.onChange(event);
    },
    [clearErrors, index, setError, t]
  );

  useEffect(() => {
    if (!isMounted) {
      return;
    }

    setValue('Contract', '');
  }, [setValue, watchSpecies]);

  const handleFieldOnChange = useCallback((fieldValue, index) => {
    setValue(`Products[${index}].ProductPlatformId`, fieldValue);
    setValue(`Products[${index}].CreateProductName`, fieldValue);
  }, []);

  return (
    <div key={index} className={styles.container}>
      <SingleAccordion
        title={accordionTitle()}
        opened={true}
        titleButton={
          !!properties?.ReadOnly ? null : onDelete.bind(this, index)
        }>
        <Grid columns={12}>
          <div className={styles.section}>
            {showPriceBreakDown ? (
              <PriceBreakDown index={index} />
            ) : toggleCalculator ? (
              <div className={styles.calculatorContainer}>
                <Calculator
                  index={index}
                  product={product}
                  onConfirm={() => setToggleCalculator(false)}
                  onCancel={() => setToggleCalculator(false)}
                />
              </div>
            ) : (
              <Controller
                name={`Products[${index}].${ProductPlatformId.Key}`}
                defaultValue={ProductPlatformId.Value || ''}
                rules={{
                  required: {
                    value: validation,
                    message: t('Field is required'),
                  },
                }}
                render={({ field }) => (
                  <div className={styles.productFieldContainer}>
                    <MuiAutocomplete
                      {...field}
                      disableClearable
                      freeSolo={false}
                      label={ProductPlatformId.Label}
                      required={ProductPlatformId.Required}
                      options={productOptions}
                      placeholder={t('Select')}
                      disabled={
                        !!ProductPlatformId.Readonly ||
                        !!selectedStep?.Done ||
                        productOptions.length === 0
                      }
                      value={watchProductId}
                      addCustomOption={
                        !!product.ProductPlatformId.CreateProduct
                      }
                      fieldOnChange={(e) => {
                        handleFieldOnChange(e.target.value, index);
                      }}
                      error={error && error.ProductPlatformId}
                    />
                  </div>
                )}
              />
            )}
          </div>
        </Grid>
        <Grid columns={4}>
          <Controller
            name={`Products[${index}].${UnitPrice?.Key}`}
            rules={{ validate: unitPriceValidation }}
            render={({ field }) => (
              <MuiOutlinedInput
                {...field}
                onChange={onChangeUnitPriceHandler.bind(this, field)}
                label={unitPriceLabel}
                disabled={!!UnitPrice?.Readonly || !!selectedStep?.Done}
                required={!!UnitPrice?.Required}
                placeholder={t('Enter')}
                textAlign={'right'}
                fontWeight={'bold'}
                type={'number'}
                error={
                  errors?.Products &&
                  errors.Products[index] &&
                  errors.Products[index]?.UnitPrice
                }
                start={
                  <CalculateIcon
                    sx={{ cursor: 'pointer' }}
                    fontSize='large'
                    // onClick={(event) => setToggleCalculator(true)}
                  />
                }
                value={watchPriceUnitPrice}
              />
            )}
          />
        </Grid>
        <Grid columns={3}>
          <Controller
            name={`Products[${index}].${NetWeight.Key}`}
            // defaultValue={NetWeight.Value || ''}
            rules={{
              required: {
                value: validation,
                message: t('Field is required'),
              },
              validate: netWeightValidation,
            }}
            render={({ field }) => (
              <MuiOutlinedInput
                {...field}
                onBlur={onBlurProductNetWeightHandler}
                onChange={onChangeNetWeightHandler.bind(this, field)}
                // defaultValue={NetWeight.Value}
                label={volumeLabel}
                type={NetWeight.Type}
                disabled={
                  !watchSpecies || !!NetWeight.Readonly || !!selectedStep?.Done
                }
                required={!!NetWeight.Required}
                placeholder={t('Enter')}
                textAlign={'right'}
                fontWeight={'bold'}
                error={
                  errors?.Products &&
                  errors.Products[index] &&
                  errors.Products[index]?.NetWeight
                }
                value={watchProductNetWeight}
              />
            )}
          />
        </Grid>
        {/* <Grid columns={2}>
          <Controller
            name={`Products[${index}].${'Containers'}`}
            defaultValue={Containers?.Value || ''}
            render={({ field }) => (
              <MuiOutlinedInput
                {...field}
                onChange={(event) => {
                  if (
                    !!event.target.value &&
                    Number(event.target.value) < Number(minContainersValue)
                  ) {
                    field.onChange(minContainersValue);
                  } else {
                    field.onChange(event.target.value);
                  }
                }}
                defaultValue={Containers?.Value}
                label={Containers?.Label}
                disabled={false}
                required={!!Containers?.Required}
                placeholder={'Enter'}
                textAlign={'right'}
                start={<WidgetsIcon fontSize='large' />}
              />
            )}
          />
        </Grid> */}
        <Grid columns={5}>
          {!!watchPriceType && <ProductSummary productIndex={index} />}
        </Grid>
      </SingleAccordion>
    </div>
  );
};

export default memo(ProductAccordion);

import React, {
  useState,
  useCallback,
  memo,
  useEffect,
  Fragment,
  useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, FormProvider, useForm } from 'react-hook-form';

import { updateButtons } from 'Components/DealSteps/StepInputs';
import { useStepSettingsContext } from '@lib/Context/StepSettings/step-settings.context';
import { formatNumber } from '@lib/helpers/format-number.helper';
import { convertCurrencyHelper } from '@lib/helpers/convert-currency.helper';
import { formatPrice } from '@lib/helpers/helpers';
import Button from '@atoms/Button/button.component';
import Grid from '@atoms/Grid/DynamicGrid/grid.component';
import TooltipComponent from '@atoms/Tooltip/tooltip.component';
import MuiOutlinedInput from '@atoms/Fields/ReactFormFields/FormInput/MuiOutlinedInput/mui-outlined-input.component';
import FormInputFields from '@atoms/Fields/ReactFormFields/FormInputField/form-input-field.component';

import styles from './pc-step-form.module.scss';

const PCStepForm = ({ sample, onSubmit }) => {
  const { t } = useTranslation();
  const { stepSettingsContext, setStepSettingsContext } =
    useStepSettingsContext();

  const [sampleButtons, setSampleButtons] = useState([]);

  const defaultFieldsValue = useMemo(() => {
    const fields = sample?.Fields.reduce((acc, curr) => {
      acc[curr.Key] = curr.Value || '';
      return acc;
    }, {});

    return fields;
  }, [sample]);

  const form = useForm({
    defaultValues: {
      ...defaultFieldsValue,
    },
  });

  const {
    handleSubmit,
    watch,
    setValue,
    reset,
    setError,
    clearErrors,
    formState: { errors, isDirty },
  } = form;

  const fieldNames = {
    buyerReference: 'BuyerReference',
    sellerReference: 'SellerReference',
    contract: 'FuturesContractCode',
    date: 'PriceConfirmationDate',
    futureContracts: 'NumberFutureContracts',
    contractPrice: 'ContractPrice',
    differential: 'DifferentialPricePc',
    finalPrice: 'FinalPrice',
  };

  //reset form on sample change
  useEffect(() => {
    reset(defaultFieldsValue);
  }, [defaultFieldsValue]);

  const watchContractPrice = watch(fieldNames.contractPrice);
  const watchDifferential = watch(fieldNames.differential);
  const watchFinalPrice = watch(fieldNames.finalPrice);
  const watchFuturesContracts = watch(fieldNames.futureContracts);

  useEffect(() => {
    const newButtons = isDirty ? updateButtons(sampleButtons) : sampleButtons;

    setSampleButtons(newButtons);
  }, [isDirty]);

  useEffect(() => {
    if (sample && sample?.Buttons) {
      setSampleButtons(sample?.Buttons);
    } else {
      setSampleButtons([]);
    }

    setStepSettingsContext(sample.Settings);
  }, [sample]);

  const netWeight = useMemo(
    () => (stepSettingsContext?.Species === 'Coffee Robusta' ? 'MT' : 'lbs'),
    [stepSettingsContext?.Species]
  );

  const onSubmitHandler = useCallback(
    (buttonType, currentStepId, formData, settings) => {
      onSubmit({
        ...formData,
        ButtonType: buttonType,
        currentStepId,
        settings: settings,
      });
      reset(formData);
    },
    [onSubmit, reset]
  );

  const fieldsGrid = (key) => {
    switch (key) {
      case fieldNames.buyerReference:
        return 6;
      case fieldNames.sellerReference:
        return 6;
      case fieldNames.date:
        return 6;
      case fieldNames.contract:
        return 6;
      case fieldNames.futureContracts:
        return 6;
      case fieldNames.contractPrice:
        return 6;
      case fieldNames.finalPrice:
        return 6;
      case fieldNames.differential:
        return 6;
      default:
        return 6;
    }
  };

  //Calculate Final Price
  useEffect(() => {
    if (watchDifferential && watchContractPrice) {
      let price =
        parseFloat(watchContractPrice) + parseFloat(watchDifferential);
      // price = parseFloat(formatPrice(Math.abs(Number(price)))) // use the correct format

      setValue(fieldNames.finalPrice, price);
    }
  }, [watchContractPrice, watchDifferential]);

  const unitPriceValidation = useCallback(
    (value) => {
      if (watchFuturesContracts === 'Outright' && Number(value) < 0) {
        return t('PositiveNumberValidation');
      }
      return true;
    },
    [t, watchFuturesContracts]
  );

  const onChangeNetWeightHandler = useCallback(
    (field, event) => {
      //check if a positive number
      if (Number(Number(event?.target?.value)) < 0) {
        setError(fieldNames.futureContracts, {
          type: 'error',
          message: t('PositiveNumberValidation'),
        });
      }
      //Check if a decimal
      else if (event?.target?.value % 1 !== 0) {
        setError(fieldNames.futureContracts, {
          type: 'error',
          message: t('WholeNumberValidation'),
        });
      } else {
        clearErrors(fieldNames.futureContracts);
      }
      field.onChange(event);
    },
    [clearErrors, setError, t]
  );

  const renderFinalPrice = useCallback(() => {
    const currency = convertCurrencyHelper(
      stepSettingsContext?.UnitPriceCurrencyId
    );

    const price = Number(watchFinalPrice);
    const sign = price > 0 ? '' : price < 0 ? '-' : '';
    return (
      <div className={styles.finalPriceWrap}>
        <div>
          <b>{t('Final Price')}:</b>
        </div>
        {watchFinalPrice ? (
          <div>
            <b>
              {watchFinalPrice && String(watchFinalPrice)?.includes('.00')
                ? formatPrice(Math.abs(price), currency, sign)
                : formatPrice(watchFinalPrice)}
              /{netWeight}
            </b>
          </div>
        ) : null}
      </div>
    );
  }, [netWeight, stepSettingsContext?.UnitPriceCurrencyId, t, watchFinalPrice]);

  // ADD (currency/VolumeUnit) to the title
  const renderDifferentialLabel = useCallback(
    (formField) => {
      if (
        stepSettingsContext?.UnitPriceCurrencyId &&
        stepSettingsContext?.NetWeightUnitId
      ) {
        if (
          formField.Key === fieldNames.differential ||
          formField.Key === fieldNames.contractPrice
        ) {
          return `${formField.Label} (${stepSettingsContext?.UnitPriceCurrencyId}/${netWeight})`;
        }
      }

      return formField.Label;
    },
    [stepSettingsContext]
  );

  const renderField = useCallback(
    (formField) => {
      switch (formField.Key) {
        case fieldNames.finalPrice:
          return renderFinalPrice();
        case fieldNames.contractPrice:
          if (!formField?.Label.includes('/')) {
            formField.Label = renderDifferentialLabel(formField);
          }
          break;
        case fieldNames.futureContracts:
          return (
            <Controller
              name={formField.Key}
              defaultValue={formField.Value}
              rules={{ validate: unitPriceValidation }}
              render={({ field }) => {
                return (
                  <Grid columns={fieldsGrid(formField.Key)}>
                    <MuiOutlinedInput
                      {...field}
                      onChange={(e) => onChangeNetWeightHandler(field, e)}
                      label={formField.Label}
                      disabled={!!formField.Readonly || !!sample.Step.Done}
                      required={!!formField.Required}
                      placeholder={t('Enter')}
                      type={'number'}
                      error={errors[fieldNames.futureContracts]}
                    />
                  </Grid>
                );
              }}
            />
          );
        default:
          break;
      }

      return (
        <Controller
          name={formField.Key}
          defaultValue={formField.Value}
          render={({ field }) => {
            return (
              <Grid columns={fieldsGrid(formField.Key)}>
                <FormInputFields
                  info={{ ...field }}
                  minDate={false}
                  fields={formField}
                  onChange={field.onChange}
                  disabled={!!formField.Readonly || !!sample.Step.Done}
                  label={renderDifferentialLabel(formField)}
                  prefix={'PC'}
                />
              </Grid>
            );
          }}
        />
      );
    },
    [sample, watchFinalPrice, stepSettingsContext]
  );

  const buttonsColor = (buttonType) => {
    switch (buttonType) {
      case 'saveDraft':
        return `quaternary`;
      case 'rejected':
        return `tertiary`;
      case 'saveForceComplete':
      case 'saveForceApprove':
        return `senary`;
      default:
        return 'primary';
    }
  };

  const renderButton = (button) => {
    return (
      <TooltipComponent key={button.Name} text={t(button.Text)}>
        <Button
          onClick={handleSubmit((formData) => {
            onSubmitHandler(
              button.Type,
              sample?.Step?.Id,
              formData,
              sample?.Settings
            );
          })}
          size={'xxl'}
          btnColor={buttonsColor(button.Type)}
          disabled={
            Object.keys(errors).length > 0
              ? true
              : button.Disable
              ? true
              : false
          }>
          {t(button.Name)}
        </Button>
      </TooltipComponent>
    );
  };

  return (
    <FormProvider {...form}>
      {sample?.Fields.map((formField, i) => (
        <Fragment key={i}>{renderField(formField)}</Fragment>
      ))}
      <div className={styles.buttonsWrap}>
        <div className={styles.saveButtons}>
          {!!sampleButtons?.length && sampleButtons.map(renderButton)}
        </div>
      </div>
    </FormProvider>
  );
};

export default memo(PCStepForm);

import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import TermsAndConditionsMessage from '@organisms/TermsAndConditions/terms-and-conditions-message.component';
import { SingleDocument } from '../SingleDocument/single-document.component';
import EditIcon from '@mui/icons-material/Edit';

import styles from './list-documents.style.module.scss';
import { useManageDealContext } from '@lib/Context/ManageDealContext/manage-deal.context';

const Component = ({
  documents,
  canWrite,
  selectStep,
  getAllDealDocumentsHandler,
  ...props
}) => {
  const { t } = useTranslation();
  const { deal } = useManageDealContext();

  return (
    <>
      <div className={styles.title}>{t('DealDocuments')}</div>
      {documents.map((step) => (
        <div key={step.Id}>
          <div className={styles.documentName}>
            {t(step.Name)}{' '}
            <EditIcon
              fontSize='medium'
              style={{ marginLeft: '10px', color: 'gray', cursor: 'pointer' }}
              onClick={() => {
                selectStep(step, 'steps');
              }}
            />
          </div>
          {step.Documents.map((document) => (
            <SingleDocument
              key={document.Id}
              stepDocument={document}
              canWrite={canWrite}
              getAllDealDocumentsHandler={getAllDealDocumentsHandler}
              activePage={'documents'}
              {...props}
            />
          ))}
          {/* <TermsAndConditionsMessage /> */}
        </div>
      ))}
    </>
  );
};

export const ListDocuments = memo(Component);

import React, { Fragment } from 'react';
import { useDropzone } from 'react-dropzone';
import { Trans, useTranslation } from 'react-i18next';

import UploadIcon from '@assets/icons/Upload';
import FieldError from '@atoms/Fields/ReactFormFields/FieldError/field-error.component';

import styles from './styled-dropzone-v2.module.scss';
import DocumentsTitle from '@organisms/Documents/DocumentsTitle/documents-title.component';
import { color } from 'highcharts';

const StyledDropzoneV2 = ({ onDropHandler, document, step }) => {
  const { t } = useTranslation();

  const onDrop = (acceptedFiles) => {
    onDropHandler(acceptedFiles);
  };

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
    fileRejections,
  } = useDropzone({
    maxFiles: 1,
    onDrop,
  });

  const allowedFormats = ['pdf', 'doc', 'docx', 'png', 'jpeg', 'jpg'];

  return (
    <div className={styles.uploadFileContainer}>
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        <div
          className={`${styles.dropzone} ${
            isDragAccept ? `${styles.accept}` : ''
          } ${isDragReject ? 'reject' : ''}`}>
          <div style={{ width: '55%' }}>
            <DocumentsTitle
              document={document}
              step={step}
              file={document.Files[0]}
              type={'attach'}
            />
          </div>
          <UploadIcon />

          {isDragActive ? (
            <div className={styles.text}>
              <h5>{t('Drop the files here')} ...</h5>
            </div>
          ) : (
            <div className={styles.text}>
              <h5>
                <div>
                  <Trans i18nKey='DragAndDrop' components={{ p: <span /> }} />
                </div>
              </h5>
              <p>
                {t('Supported file formats')}:{' '}
                <span className={'lowercase'}>
                  {allowedFormats?.map((format, i) => {
                    // map the formats
                    if (i < allowedFormats?.length - 1) {
                      return <Fragment key={i}>{format}, </Fragment>;
                    } else {
                      return <Fragment key={i}>{format}</Fragment>;
                    }
                  })}
                </span>
              </p>
              <p>
                <Trans i18nKey='FileLimit' values={{ size: 50 }} />
              </p>
            </div>
          )}
        </div>
      </div>

      <div className={'upload-file__errors'}>
        {fileRejections.length > 0 && (
          <FieldError
            error={
              fileRejections[0].errors[0].code === 'file-invalid-type'
                ? 'The type of the file is incorrect'
                : fileRejections[0].errors[0].message
            }
          />
        )}
      </div>
    </div>
  );
};

export default StyledDropzoneV2;

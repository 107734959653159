import { memo, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import HistoryIcon from '@mui/icons-material/History';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { Box, IconButton, Typography } from '@mui/material';
import { Tooltip } from '@mui/material';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import AttachFileIcon from '@mui/icons-material/AttachFile';

import { useWindowSize } from '@lib/hooks/useWindowSize';
import documentsService from '@lib/services/documents-service';
import { useManageDealContext } from '@lib/Context/ManageDealContext/manage-deal.context';
import { stepStatusCompleted } from '@lib/helpers/StepHelper/step-status.helper';
import DocuSignSvg from '@base/svg/docusign';
import DeclinedSvg from '@base/svg/contract-notfiled-line';
import PendingApprovalSvg from '@base/svg/contract-pending-line';
import DocumentSignatureSvg from '@base/svg/contract-sign-line';
import { MenuButton } from '@atoms/MenuButton/menu-button.component';
import Portal from '@molecules/Portal/portal.component';
import { PurchaseContractDocuSign } from '@organisms/Dialogs/PurchaseContractDocuSign/purchase-contract-docusign.component';
import PopupLayout from '@organisms/Popup/PopupLayout/popup-layout.component';
import { RemainingSignatures } from '@organisms/Dialogs/RemainingSignatures/remaining-signatures.component';
import { UploadDocument } from '@organisms/Dialogs/UploadDocument/upload-document.component';
import '@css-variables';

import styles from './document-file.style.module.scss';
import { useUserRole } from '@lib/hooks/useUserRole';

const Component = ({
  file,
  archivedDeal,
  onDeleteDocument,
  onDeleteVersion,
  onSubmitForSignatureDocument,
  onRefreshDocuments,
  noEditAction,
  showStatus,
  step,
  stepDocument,
  type,
  documentType,
  //canWrite,
  activePage,
}) => {
  const { width } = useWindowSize();
  const { t } = useTranslation();
  const {
    deal,
    selectedStep: selectedStepContext,
    canWrite,
  } = useManageDealContext();

  const [docosignDialog, setDocosignDialog] = useState(false);
  const [remainingSignaturesDialog, setRemainingSignaturesDialog] =
    useState(false);
  const [uploadDocsDialog, setUploadDocsDialog] = useState(false);
  const { isFinancier, isInsurer, isWarehouseAgent } = useUserRole();

  const getDocumentsR = (file) => {
    documentsService.getDocument(file.FilePath).then((r) => {
      const blobFile = new Blob([r.data]);
      const fileURL = URL.createObjectURL(blobFile);
      const link = document.createElement('a');
      link.href = fileURL;
      link.setAttribute('download', file.FileName);
      link.click();
    });
  };

  const openDocumentHandler = (file) => {
    if (width > 800) {
      if (!!file?.FilePath) {
        if (file.FilePath.split('.').pop() === 'pdf')
          window.open(`/download/${file.FilePath}`);
        else {
          getDocumentsR(file);
        }
      }
    } else {
      getDocumentsR(file);
    }
  };

  const openSignDocumentDialog = useCallback(() => {
    if (stepDocument?.RemainingDocusignSubmits <= 0) {
      setRemainingSignaturesDialog(true);
    } else {
      if (!file.ShowSignatureButton) {
        return;
      }
      setDocosignDialog(true);
    }
  }, [file.ShowSignatureButton, stepDocument?.RemainingDocusignSubmits]);

  const menuItems = useMemo(() => {
    const items = [];

    if (
      stepDocument?.Files?.length > 0 &&
      selectedStepContext?.StepMainDocument === stepDocument?.Name &&
      !stepStatusCompleted(selectedStepContext?.Status) &&
      !isFinancier &&
      !isInsurer &&
      !isWarehouseAgent
    ) {
      items.push({
        name: t('Attach'),
        icon: AttachFileIcon,
        onClick: () => {
          setUploadDocsDialog(true);
        },
      });
    }
    if (
      !archivedDeal &&
      !noEditAction &&
      !type &&
      !isFinancier &&
      !isInsurer &&
      !isWarehouseAgent
    ) {
      items.push({
        name: !file.CanDelete ? t('History') : t('History'),
        icon: HistoryIcon,
        onClick: () => {
          setUploadDocsDialog(true);
          //updateDocument(file.DocumentId, !file.CanDelete);
        },
      });
    }
    if (file.ShowSignatureButton) {
      items.push({
        name: t('Sign Document'),
        icon: DocuSignSvg,
        iconProps: {
          height: 23,
          width: 23,
        },
        onClick: openSignDocumentDialog,
      });
    }
    if (
      !(isFinancier || isInsurer || isWarehouseAgent) &&
      !!file.CanDelete &&
      !stepStatusCompleted(selectedStepContext?.Status) &&
      canWrite
    ) {
      items.push({
        name: t('Delete'),
        icon: DeleteForeverIcon,
        iconProps: {
          style: { fill: '#d40014' },
        },
        onClick: () => {
          if (type === 'create') {
            onDeleteDocument();
          } else if (typeof onDeleteVersion === 'function') {
            onDeleteVersion();
          } else if (typeof onDeleteDocument === 'function') {
            onDeleteDocument();
          }
        },
      });
    } else if (
      (isFinancier || isInsurer || isWarehouseAgent) &&
      activePage === 'ServiceOrders' &&
      canWrite &&
      !!file.CanDelete
    ) {
      items.push({
        name: t('Delete'),
        icon: DeleteForeverIcon,
        iconProps: {
          style: { fill: '#d40014' },
        },
        onClick: () => {
          if (type === 'create') {
            onDeleteDocument();
          } else if (typeof onDeleteVersion === 'function') {
            onDeleteVersion();
          } else if (typeof onDeleteDocument === 'function') {
            onDeleteDocument();
          }
        },
      });
    }

    return items;
  }, [
    archivedDeal,
    file.CanDelete,
    file.ShowSignatureButton,
    noEditAction,
    onDeleteDocument,
    onDeleteVersion,
    t,
    openSignDocumentDialog,
  ]);

  const submitForSignature = useCallback(
    (dialogData) => {
      const { LatestDocumentVersionId } = file;
      const payload = dialogData || {
        BuyerEmail: deal?.BuyerEmail || '',
        SellerEmail: deal?.SellerEmail || '',
        LatestDocumentVersionId,
      };

      return onSubmitForSignatureDocument({
        ...payload,
        LatestDocumentVersionId,
      }).then((data) => {
        setDocosignDialog(false);
        return data;
      });
    },
    [deal?.BuyerEmail, deal?.SellerEmail, file, onSubmitForSignatureDocument]
  );

  const renderDocumentStatusIcon = useCallback(
    (file) => {
      switch (file.Status) {
        case 'pendingApproval':
          return (
            <Box sx={{ padding: '0 8px' }}>
              <PendingApprovalSvg height={38} width={38} fill={'#E29300'} />
            </Box>
          );
        case 'approved':
        case 'failedSubmission':
          return (
            <IconButton
              sx={{ padding: '0 8px' }}
              onClick={openSignDocumentDialog}>
              <DocuSignSvg height={38} width={38} />
            </IconButton>
          );
        case 'cancelled':
        case 'failedDocusignDecline':
          return (
            <Box sx={{ padding: '0 8px' }}>
              <DeclinedSvg height={38} width={38} fill={'#d40014'} />
            </Box>
          );
        case 'sentToDocusign':
          return (
            <Box sx={{ padding: '0 8px' }}>
              <DocumentSignatureSvg height={38} width={38} fill={'#E29300'} />
            </Box>
          );
        case 'pendingSignature':
          return (
            <Box sx={{ padding: '0 8px' }}>
              <DocumentSignatureSvg height={38} width={38} fill={'#E29300'} />
            </Box>
          );
        case 'signed':
          return (
            <Box sx={{ padding: '0 8px' }}>
              <DocumentSignatureSvg height={38} width={38} fill={'#4241DD'} />
            </Box>
          );
        default:
          return <></>;
      }
    },
    [openSignDocumentDialog]
  );

  return (
    <div className={styles.document}>
      {docosignDialog && (
        <Portal overlay={'overlay'}>
          <PopupLayout closePopUp={setDocosignDialog}>
            {/* <Dialog open={dialog} onClose={() => setDialog(false)}> */}
            <PurchaseContractDocuSign
              onSubmit={submitForSignature}
              onClose={() => setDocosignDialog(false)}
            />
            {/* </Dialog> */}
          </PopupLayout>
        </Portal>
      )}
      {uploadDocsDialog && type === 'create' && (
        <Portal overlay={'overlay'}>
          <PopupLayout closePopUp={setUploadDocsDialog}>
            <UploadDocument
              title={t('ManageDocuments')}
              isEdit={false}
              type={'create'}
              documentType={documentType}
              onRefreshDocuments={onRefreshDocuments}
              documentTypeId={stepDocument.Id}
              canWrite={canWrite}
            />
          </PopupLayout>
        </Portal>
      )}
      {uploadDocsDialog && !type && (
        <Portal overlay={'overlay'}>
          <PopupLayout closePopUp={setUploadDocsDialog}>
            <UploadDocument
              title={t('ManageDocuments')}
              isEdit={true}
              onClose={() => setUploadDocsDialog(false)}
              step={step}
              documentId={file.DocumentId}
              dealStepId={stepDocument.DealStepId}
              isLocked={stepDocument.IsLocked}
              onRefreshDocuments={onRefreshDocuments}
              documentTypeId={stepDocument.Id}
              canWrite={canWrite}
              serviceType={stepDocument.ServiceType}
            />
          </PopupLayout>
        </Portal>
      )}
      {remainingSignaturesDialog && (
        <Portal overlay={'overlay'}>
          <PopupLayout closePopUp={setRemainingSignaturesDialog}>
            <RemainingSignatures
              onSubmit={() => setRemainingSignaturesDialog(false)}
            />
          </PopupLayout>
        </Portal>
      )}
      <div className={styles.documentDetails}>
        <Box display={'flex'}>
          {!!file?.Status && (
            <Tooltip
              enterTouchDelay={0}
              placement='top'
              title={
                <Typography style={{ fontSize: 13 }}>
                  {t(file.Status)}
                </Typography>
              }>
              {renderDocumentStatusIcon(file)}
            </Tooltip>
          )}
          <div>
            <div
              className={styles.documentTitle}
              onClick={() => openDocumentHandler(file)}>
              <div
                style={{
                  display: 'flex',
                }}>
                <CloudDownloadIcon />
                {activePage === 'documents' ? (
                  <p className={styles.name}>{stepDocument?.Name}</p>
                ) : (
                  <>
                    {' '}
                    <p className={styles.documentName} title={file.FileName}>
                      <span className={styles.versionNumber}>
                        #{file.VersionNumber} -{' '}
                      </span>
                      {file.FileName}
                    </p>
                  </>
                )}
              </div>
              {activePage === 'documents' && (
                <div>
                  <p className={styles.documentName} title={file.FileName}>
                    <span className={styles.versionNumber}>
                      #{file.VersionNumber} -{' '}
                    </span>
                    {file.FileName}
                  </p>
                </div>
              )}
            </div>
            <div className={styles.uploadInformation}>
              <p>
                {t('Uploaded')} {file.Date}
              </p>
              <p>
                {t('By')} {file.User}
              </p>
            </div>
          </div>
        </Box>
      </div>
      {showStatus && file?.Status && (
        <div className={`${styles.fileStatus} ${styles[file.Status]}`}>
          {t(file.Status)}
        </div>
      )}

      <div className={styles.fileActions}>
        {!noEditAction && !!menuItems.length && canWrite && (
          <MenuButton menuItems={menuItems} />
        )}
      </div>
    </div>
  );
};

export const DocumentFile = memo(Component);
